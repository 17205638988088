import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

type State = {
  business: {
    id: string;
    primaryName: string;
    displayName: string;
    slug: string;
    legalStructure?: string;
  };
};

type Actions = {
  setBusiness: (business: State) => void;
};

export const useBusinessStore = create<State & Actions>()(
  immer((set) => ({
    business: {
      id: "",
      primaryName: "",
      displayName: "",
      slug: "",
      legalStructure: undefined,
    },
    setBusiness: ({ business }: State) =>
      set((state: State) => {
        state.business = business;
      }),
  })),
);
